import styles from "../../../styles/layout/BlockAboutFood/AboutFood.module.scss";
import { discript, dataDiscriptPhoto } from "./dataAboutFood";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
import menuRest from "../../../data/menu_pticy.pdf";
import menuBar from "../../../data/bar_menu_pticy.pdf";
import menuPost from "../../../data/post_menu.pdf";
// import {Link} from 'react-router-dom';

const AboutFood = () => {
  const HoverMiniBlock = ({ titel, discript }) => {
    return (
      <div className={styles.blockHoverMiniDiscript}>
        <h2 className={styles.textHoverTitelMain}>
          {titel.toLocaleUpperCase()}
        </h2>
        <p className={styles.textHoverDiscriptMain}>{discript}</p>
      </div>
    );
  };

  const BlockDiscriptAboutFoodSwitch = () => {
    return (
      <>
        <div className={styles.blockDiscriptPhoto}>
          {dataDiscriptPhoto.slice(1, 5).map((elem, index) => (
            <div key={index} className={styles.blockContentDiscriptPhoto}>
              <img loading="lazy" src={elem?.photo} />
              <HoverMiniBlock titel={elem?.titel} discript={elem?.discript} />
            </div>
          ))}
        </div>
        <div className={styles.blockDiscriptMobile}>
          {dataDiscriptPhoto.slice(1, 4).map((elem, index) => (
            <div
              key={index}
              style={
                index % 2 === 0
                  ? {
                      flexDirection: "row",
                    }
                  : { flexDirection: "row-reverse" }
              }
              className={styles.blockContentMobDiscriprt}
            >
              <div className={styles.blockPhotoMobile}>
                <img loading="lazy" src={elem?.photo} />
              </div>
              <div className={styles.blockTextHMobile}>
                <h2 className={styles.textMobileDiscriptHTitel}>
                  {elem?.titel.toLocaleUpperCase()}
                </h2>
                <p className={styles.textMobileDiscriptHDis}>
                  {elem?.discript}
                </p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const onButtonClick = (path, name) => {
    const pdfUrl = path;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = name; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section id="memu" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h2 className={styles.textTitel}>НАСЛАДИТЕСЬ</h2>
      </div>
      <div className={styles.blockDiscript}>
        <p className={styles.textDiscript}>{discript}</p>
        <div className={styles.blockDiscriptButton}>
          <buttton
            onClick={() => onButtonClick(menuRest, "menu_pticy.pdf")}
            className={styles.buttonDiscript}
          >
            МЕНЮ РЕСТОРАНА
          </buttton>
          <buttton
            onClick={() => onButtonClick(menuBar, "bar_menu_pticy.pdf")}
            className={styles.buttonDiscript}
          >
            БАРНАЯ КАРТА
          </buttton>
          <buttton
            onClick={() => onButtonClick(menuPost, "menu_post.pdf")}
            className={styles.buttonDiscript}
          >
            {"постное меню".toUpperCase()}
          </buttton>
        </div>
      </div>
      <div className={styles.blockPhotoAboutFood}>
        <div className={styles.blockMainPhotoAboutFood}>
          <img
            // src={
            //   "https://krasnayapolyanaresort.ru/assets/images/blog/frirajd_7.png"
            // }
            src={dataDiscriptPhoto[0].photo}
          />
          <div className={styles.blockTextMainAboutFood}>
            <h2 className={styles.textTitelMainPhoto}>
              {dataDiscriptPhoto[0].titel.toUpperCase()}
            </h2>
            <p className={styles.textDiscriptMainPhoto}>
              {dataDiscriptPhoto[0].discript}
            </p>
          </div>
          <div className={styles.blockHoverMainAboutFood}>
            <h2 className={styles.textHoverTitelMain}>
              {dataDiscriptPhoto[0].titel.toUpperCase()}
            </h2>
            <p className={styles.textHoverDiscriptMain}>
              {dataDiscriptPhoto[0].discript}
            </p>
          </div>
        </div>
        <BlockDiscriptAboutFoodSwitch />
      </div>
    </section>
  );
};

export { AboutFood };
